import * as states from './states.js'

export const canScanCode = state => {
  switch (state.state) {
    case states.IDLE:
    case states.SCANNED_RETURNABLE:
    case states.SCANNED_UNKNOWN:
      return true
    default:
      return false
  }
}

export const canReturnItem = state => {
  switch (state.state) {
    case states.SCANNED_RETURNABLE:
    case states.RETURN_ITEM_ERROR:
      return true
    default:
      return false
  }
}

export const canRetryReturn = state => (
  state.state === states.RETURN_ITEM_ERROR
)

export const canCancelReturn = state => (
  state.state === states.RETURN_ITEM_ERROR
)

export const canScanAnother = state => (
  state.state === states.RETURN_ITEM_SUCCESS
)

export const isBusy = state => {
  switch (state.state) {
    case states.SCANNING_CODE:
    case states.RETURNING_ITEM:
      return true
    default:
      return false
  }
}

export const isSuccessful = state => (
  state.state === states.RETURN_ITEM_SUCCESS
)

export const hasConfirmedSelection = state => {
  switch (state.state) {
    case states.RETURNING_ITEM:
    case states.RETURN_ITEM_SUCCESS:
    case states.RETURN_ITEM_ERROR:
      return true
    default:
      return false
  }
}
