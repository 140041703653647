import React from 'react';
import ReactDOM from 'react-dom';
import HttpsRedirect from 'react-https-redirect';
import 'webrtc-adapter';

import { createStore, applyMiddleware } from 'redux';
import { Provider as ReduxProvider } from 'react-redux';
import { createLogger } from 'redux-logger';
import thunk from 'redux-thunk';

import App from 'pages/App';
import rootReducer from './reducers';
import * as serviceWorker from './serviceWorker';
import './styles.css';

const reduxMiddleware = [thunk];
if (process.env.NODE_ENV === 'development') {
  const logger = createLogger({});
  reduxMiddleware.push(logger);
}

const store = createStore(rootReducer, applyMiddleware(...reduxMiddleware));

ReactDOM.render(
  <HttpsRedirect>
    <ReduxProvider store={store}>
      <App />
    </ReduxProvider>
  </HttpsRedirect>,
  document.getElementById('root')
);

// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: http://bit.ly/CRA-PWA
serviceWorker.unregister();
