import { createPackage } from '../../api/deliveries.js';

import {
  createClear,
  createStart,
  createSuccess,
  createError
} from './actions.js';

let timer;

export const create = (deliveryId) => {
  return (dispatch) => {
    dispatch(createStart());
    clearTimeout(timer);

    return createPackage(deliveryId)
      .then(response => {
        dispatch(createSuccess(response));
        clearTimeout(timer);
      }).catch(error => {
        dispatch(createError(error.message));
        clearTimeout(timer);
        timer = setTimeout(() => dispatch(createClear()), 3000);
      });
  }
};

export { createClear };
