import * as types from './types.js';

const INITIAL_STATE = {
  videoDevices: null,
  deviceId: null,
  isDetecting: false,
  isStreaming: false,
  error: null,
};

const selectDeviceReducer = (state, action) => {
  const { deviceId } = action.data;
  const { videoDevices } = state;

  if (deviceId && videoDevices && videoDevices.find(device => device.deviceId === deviceId)) {
    return {
      ...state,
      deviceId,
    };
  } else {
    return {
      ...state,
      deviceId: null,
      error: new Error('Unexpected device ID'),
    };
  }
};

const reducer = (state = INITIAL_STATE, action) => {
  switch (action.type) {
    case types.DETECT_START:
      return {
        ...state,
        deviceId: null,
        isDetecting: true,
        isStreaming: false,
        error: null,
      };
    case types.DETECT_SUCCESS:
      return {
        ...state,
        videoDevices: action.data.videoDevices,
        deviceId: null,
        isDetecting: false,
        isStreaming: false,
        error: null,
      };
    case types.DETECT_ERROR:
      return {
        ...state,
        deviceId: null,
        isDetecting: false,
        isStreaming: false,
        error: action.data.error,
      }
    case types.SELECT:
      return selectDeviceReducer(state, action);
    case types.STREAM_SUCCESS:
      return {
        ...state,
        isStreaming: true,
        error: null,
      };
    case types.STREAM_ERROR:
      return {
        ...state,
        isStreaming: false,
        error: action.data.error,
      };
    default:
      return state;
  }
};

export default reducer;
