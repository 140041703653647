import * as types from './types.js';

export const loginStart = () => ({
  type: types.LOGIN_START,
});

export const loginError = (error) => ({
  type: types.LOGIN_ERROR,
  data: { error },
});

export const loginSuccess = ({email, token}) => ({
  type: types.LOGIN_SUCCESS,
  data: { email, token },
});

export const logoutSuccess = () => ({
  type: types.LOGOUT_SUCCESS,
});
