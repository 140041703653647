const defaultResult = {
  orderNumber: null,
  bayNumber: null,
  error: null,
};

const errorResult = (message = 'Invalid delivery code') => ({
  ...defaultResult,
  error: new Error(message),
});

const parseDeliveryCode = (code) => {
  const codeParts = code ? code.split(':') : [];
  if (codeParts.length < 2) {
    return errorResult();
  }

  const orderNumber = codeParts[0].trim();
  const bayNumber = codeParts[1].trim();

  if (orderNumber && bayNumber) {
    return {
      ...defaultResult,
      orderNumber,
      bayNumber,
    };
  } else {
    return errorResult();
  }
};

export default parseDeliveryCode;
