import reducer, { postReducer as scannerPostReducer } from './reducers.js';

import * as scannerActions from './actions.js';
import * as scannerTypes from './types.js';

export {
  scannerActions,
  scannerPostReducer,
  scannerTypes,
};

export default reducer;
