import reducer from './reducers.js';

import * as deviceActions from './actions.js';
import * as deviceOperations from './operations.js';
import * as deviceSelectors from './selectors.js';
import * as deviceTypes from './types.js';

export {
  deviceActions,
  deviceOperations,
  deviceSelectors,
  deviceTypes,
}

export default reducer;
