import { getUser } from '../utils/userStore.js';

const YG_SHOP_API = process.env.REACT_APP_YG_SHOP_API || '';

const requestConfig = ({body, config}) => {
  const headers = {
    'Content-Type': 'application/json',
  };

  const user = getUser();
  if (user.token && user.email) {
    headers['Authorization'] = user.token;
    headers['User-Email'] = user.email;
    headers['X-Spree-Token'] = user.spreeApiKey;
  }

  const result = {
    method: body ? 'POST' : 'GET',
    ...config,
    headers: {
      ...headers,
      ...config.headers,
    },
  };
  if (body) {
    result.body = JSON.stringify(body);
  }

  return result;
}

const client = (endpoint, {body, ...customConfig} = {}) => {
  const config = requestConfig({body, config: customConfig});
  return window
    .fetch(`${YG_SHOP_API}${endpoint}`, config)
    .then(response => {
      if (response.ok) {
        return response.json();
      } else {
        const error = new Error(`Error ${response.status}: ${response.statusText}`);
        error.response = response;
        throw error;
      }
    });
}

export default client;
