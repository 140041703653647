import React from 'react';
import { connect } from 'react-redux';

import './styles.css';

const AuthenticatedApp = React.lazy(() => import('pages/AuthenticatedApp'));
const UnauthenticatedApp = React.lazy(() => import('pages/UnauthenticatedApp'));

const AppLoading = (
  <h1 className='AppLoading'>
    Loading…
  </h1>
);

const App = ({isLoggedIn}) => {
  return (
    <React.Suspense fallback={AppLoading}>
      {isLoggedIn ? <AuthenticatedApp /> : <UnauthenticatedApp />}
    </React.Suspense>
  );
}

const mapStateToProps = state => {
  const { user } = state.auth;
  const isLoggedIn = !!user.email;

  return {
    isLoggedIn,
  };
};

const ConnectedApp = connect(mapStateToProps)(App);

export default ConnectedApp;
