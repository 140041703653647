import { detectStart, detectSuccess, detectError } from './actions.js';
import { isSupported, selectVideoDevices } from './selectors.js';

export const detect = () => {
  if (!isSupported()) {
    return detectError(new Error(
      "This browser does not support camera access. It may be out of date."
    ));
  }

  return (dispatch) => {
    dispatch(detectStart());

    navigator.mediaDevices.getUserMedia({audio: false, video: true})
      .then(stream => {
        const devices = navigator.mediaDevices.enumerateDevices();
        if (stream) stream.getTracks().forEach(track => track.stop());
        return devices;
      })
      .then(devices => {
        const videoDevices = selectVideoDevices(devices);
        if (videoDevices.length > 0) {
          return dispatch(detectSuccess({videoDevices}));
        } else {
          return dispatch(detectError(new Error("No camera detected.")));
        }
      })
      .catch(error => {
        const userError = new Error("Camera access denied.");
        userError.description = error.message;
        return dispatch(detectError(userError));
      });
  }
};
