export const isSupported = () => (
  navigator.mediaDevices &&
  navigator.mediaDevices.enumerateDevices &&
  navigator.mediaDevices.getUserMedia
);

export const selectVideoDevices = devices => (
  devices
    .filter(device => device.deviceId && device.kind === 'videoinput')
    .map(device => ({
      label: device.label,
      deviceId: device.deviceId,
    }))
);
