import * as types from './types.js';

export const detectStart = () => ({
  type: types.DETECT_START,
});

export const detectError = (error) => ({
  type: types.DETECT_ERROR,
  data: { error },
});

export const detectSuccess = ({videoDevices}) => ({
  type: types.DETECT_SUCCESS,
  data: { videoDevices },
});

export const select = ({deviceId}) => ({
  type: types.SELECT,
  data: { deviceId },
});

export const streamError = (error) => ({
  type: types.STREAM_ERROR,
  data: { error },
});

export const streamSuccess = () => ({
  type: types.STREAM_SUCCESS,
});
