const parseReturnableCode = (code) => {
  return new Promise((resolve, reject) => {
    const codeParts = code ? code.split(',') : []
    if (codeParts.length < 2) {
      return reject(new Error('Invalid format'))
    }

    const orderNumber = codeParts[0].trim()
    const returnableId = codeParts[1].trim()

    if (orderNumber && returnableId) {
      resolve({
        orderNumber,
        returnableId,
      })
    } else {
      reject(new Error('Missing data'))
    }
  })
}

export default parseReturnableCode
