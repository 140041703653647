import { returnItem as returnItemRequest } from 'api/returnables.js'

import parseReturnableCode from 'utils/parseReturnableCode.js'

import * as actions from './actions.js'
import * as selectors from './selectors.js'

export const scanCode = ({code}) => {
  return (dispatch, getState) => {
    if (!code || !selectors.canScanCode(getState().returnables)) {
      return
    }

    dispatch(actions.scanningCode({code}))

    parseReturnableCode(code)
      .then(({orderNumber, returnableId}) => {
        dispatch(actions.scannedReturnable({code, orderNumber, returnableId}))
      })
      .catch((_error) => {
        dispatch(actions.scannedUnknown({code}))
      })
  }
}

export const returnItem = () => {
  return (dispatch, getState) => {
    const { returnables } = getState()
    const { orderNumber, returnableId } = returnables.scanResult || {}

    if (!orderNumber || !selectors.canReturnItem(returnables)) {
      return
    }

    dispatch(actions.returningItem())

    returnItemRequest(({orderNumber, returnableId}))
      .then(response => {
        dispatch(actions.returnItemSuccess({
          creditAmount: response.credit_amount,
          previousTotal: response.previous_total,
          updatedTotal: response.updated_total,
        }))
      })
      .catch(error => {
        dispatch(actions.returnItemError({error: error.message}))
      })
  }
}
