import reducer from './reducers.js';

import * as authActions from './actions.js';
import * as authOperations from './operations.js';
import * as authTypes from './types.js';

export {
  authActions,
  authOperations,
  authTypes,
}

export default reducer;
