import reducer from './reducers.js'

import * as returnableActions from './actions.js'
import * as returnableOperations from './operations.js'
import * as returnableSelectors from './selectors.js'
import * as returnableStates from './states.js'

export {
  returnableActions,
  returnableOperations,
  returnableSelectors,
  returnableStates,
}

export default reducer
