import client from './client.js';

import { setUser, removeUser } from '../utils/userStore.js';

const handleUserResponse = (response) => {
  const {
    auth_token: token,
    email,
    spree_api_key: spreeApiKey,
  } = response;
  const user = { token, email, spreeApiKey };
  setUser(user);
  return user;
};

export const login = ({email, password}) => {
  const body = {login: email, password};

  return client('/users/api/sessions', {body})
    .then(handleUserResponse);
}

export const logout = () => Promise.resolve(removeUser());
