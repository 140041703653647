import client from './client.js';

const PACKAGE_TYPE = 'dry'

export const getDeliveries = () => {
  return client('/deliveries/api/deliveries');
};

export const createPackage = (deliveryId) => {
  const endpoint = `/deliveries/api/deliveries/${deliveryId}/packages`

  return client(endpoint, {
    body: {
      id: deliveryId,
      package_type: PACKAGE_TYPE,
    }
  });
};
