const localStorageKey = '__yg_user__';

const userVersion = 1;

const getUser = () => {
  const jsonData = window.localStorage.getItem(localStorageKey);
  const data = jsonData ? JSON.parse(jsonData) : {};
  return data.version === userVersion ? data : {};
};

const setUser = ({email, token, spreeApiKey}) => {
  const data = JSON.stringify({
    email,
    spreeApiKey,
    token,
    version: userVersion,
  });
  window.localStorage.setItem(localStorageKey, data);
};

const removeUser = () => {
  window.localStorage.removeItem(localStorageKey);
}

export {
  getUser,
  setUser,
  removeUser,
};
