export const IDLE = 'IDLE'
export const SCANNING_CODE = 'SCANNING_CODE'
export const SCANNED_RETURNABLE = 'SCANNED_RETURNABLE'
export const SCANNED_UNKNOWN = 'SCANNED_UNKNOWN'
export const RETURNING_ITEM = 'RETURNING_ITEM'
export const RETURN_ITEM_SUCCESS = 'RETURN_ITEM_SUCCESS'
export const RETURN_ITEM_ERROR = 'RETURN_ITEM_ERROR'

export const initialState = () => ({
  error: null,
  returnResult: null,
  scanResult: null,
  state: IDLE,
})

export const scanningCode = (state, data) => ({
  ...state,
  error: null,
  returnResult: null,
  scanResult: {
    code: data.code,
    orderNumber: null,
    returnableId: null,
    scannedAt: null,
  },
  state: SCANNING_CODE,
})

export const scannedReturnable = (state, data) => ({
  ...state,
  error: null,
  returnResult: null,
  scanResult: {
    code: data.code || null,
    orderNumber: data.orderNumber || null,
    returnableId: data.returnableId || null,
    scannedAt: data.scannedAt || null,
  },
  state: SCANNED_RETURNABLE,
})

export const scannedUnknown = (state, data) => ({
  ...state,
  error: null,
  returnResult: null,
  scanResult: {
    code: data.code || null,
    orderNumber: null,
    returnableId: null,
    scannedAt: data.scannedAt || null,
  },
  state: SCANNED_UNKNOWN,
})

export const returningItem = (state) => ({
  ...state,
  error: null,
  returnResult: null,
  state: RETURNING_ITEM,
})

export const returnItemSuccess = (state, data) => ({
  ...state,
  error: null,
  returnResult: {
    creditAmount: data.creditAmount,
    previousTotal: data.previousTotal,
    updatedTotal: data.updatedTotal,
  },
  state: RETURN_ITEM_SUCCESS,
})

export const returnItemError = (state, data) => ({
  ...state,
  error: data.error,
  state: RETURN_ITEM_ERROR,
})
