import * as actions from './actions.js'
import * as states from './states.js'

const idleReducer = (state, {type, data}) => {
  switch (type) {
    case actions.SCANNING_CODE:
      return states.scanningCode(state, data)
    default:
      return state
  }
}

const scanningReducer = (state, {type, data}) => {
  switch (type) {
    case actions.SCANNED_RETURNABLE:
      return states.scannedReturnable(state, data)
    case actions.SCANNED_UNKNOWN:
      return states.scannedUnknown(state, data)
    default:
      return state
  }
}

const scannedReturnableReducer = (state, {type, data}) => {
  switch (type) {
    case actions.SCANNING_CODE:
      return states.scanningCode(state, data)
    case actions.RETURNING_ITEM:
      return states.returningItem(state)
    default:
      return state
  }
}

const returningItemReducer = (state, {type, data}) => {
  switch (type) {
    case actions.RETURN_ITEM_SUCCESS:
      return states.returnItemSuccess(state, data)
    case actions.RETURN_ITEM_ERROR:
      return states.returnItemError(state, data)
    default:
      return state
  }
}

const returnItemSuccessReducer = (state, {type, data}) => {
  switch (type) {
    case actions.SCAN_ANOTHER:
      return states.initialState()
    default:
      return state
  }
}

const returnItemErrorReducer = (state, {type, data}) => {
  switch (type) {
    case actions.RETURNING_ITEM:
      return states.returningItem(state)
    case actions.CANCEL_RETURN:
      return states.initialState()
    default:
      return state
  }
}

const reducer = (state = states.initialState(), action) => {
  switch (state.state) {
    case states.IDLE:
    case states.SCANNED_UNKNOWN:
      return idleReducer(state, action)
    case states.SCANNING_CODE:
      return scanningReducer(state, action)
    case states.SCANNED_RETURNABLE:
      return scannedReturnableReducer(state, action)
    case states.RETURNING_ITEM:
      return returningItemReducer(state, action)
    case states.RETURN_ITEM_SUCCESS:
      return returnItemSuccessReducer(state, action)
    case states.RETURN_ITEM_ERROR:
      return returnItemErrorReducer(state, action)
    default:
      return state
  }
}

export default reducer
