import { getUser } from '../../utils/userStore.js';

import * as types from './types.js';

const initialState = () => {
  const { email, token } = getUser();
  const user = (email && token) ? ({ email, token }) : ({});

  return {
    user,
    isLoggingIn: false,
    error: null,
  };
};

const reducer = (state = initialState(), action) => {
  switch (action.type) {
    case types.LOGIN_START:
      return {
        ...state,
        user: {},
        isLoggingIn: true,
        error: null,
      };
    case types.LOGIN_SUCCESS:
      return {
        ...state,
        user: {
          email: action.data.email,
          token: action.data.token,
        },
        isLoggingIn: false,
        error: null,
      };
    case types.LOGIN_ERROR:
      return {
        ...state,
        user: {},
        isLoggingIn: false,
        error: action.data.error,
      }
    case types.LOGOUT_SUCCESS:
      return initialState();
    default:
      return state;
  }
};

export default reducer;
