import { getDeliveries } from '../../api/deliveries.js';

import { fetchStart, fetchSuccess, fetchError } from './actions.js';

const checkVanAssignments = response => {
  const missing = Object.values(response).filter(delivery => !delivery.van).length;
  if (missing > 0) {
    const prefix = missing === 1 ? 'One delivery is' : `${missing} deliveries are`;
    throw new Error(`${prefix} not assigned to a van`);
  }
  return response;
};

export const fetch = () => {
  return (dispatch) => {
    dispatch(fetchStart());

    return getDeliveries()
      .then(response => checkVanAssignments(response))
      .then(response => dispatch(fetchSuccess(response)))
      .catch(error => dispatch(fetchError(error)));
  }
};
