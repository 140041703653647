import {
  login as apiLogin,
  logout as apiLogout,
} from '../../api/auth.js';

import {
  loginStart, loginSuccess, loginError,
  logoutSuccess,
} from './actions.js';

export const login = ({email, password}) => {
  return dispatch => {
    dispatch(loginStart());

    return apiLogin({email, password})
      .then(response => dispatch(loginSuccess(response)))
      .catch(error => dispatch(loginError(error)));
  }
};

export const logout = () => {
  return dispatch => {
    return apiLogout()
      .then(() => dispatch(logoutSuccess()));
  }
}
