import client from './client.js';

export const returnItem = ({orderNumber, returnableId}) => {
  const endpoint = '/api/returnables/return_items';

  return client(endpoint, {
    body: {
      order_number: orderNumber,
      returnable_id: returnableId,
    },
  });
};
