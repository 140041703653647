import * as types from './types.js';

export const fetchStart = () => ({
  type: types.FETCH_START,
});

export const fetchError = (error) => ({
  type: types.FETCH_ERROR,
  data: { error },
});

export const fetchSuccess = (deliveries) => ({
  type: types.FETCH_SUCCESS,
  data: { deliveries },
});
