import * as types from './types.js';

export const createClear = () => ({
  type: types.CREATE_CLEAR,
});

export const createStart = () => ({
  type: types.CREATE_START,
});

export const createError = (error) => ({
  type: types.CREATE_ERROR,
  data: { error },
});

export const createSuccess = (packages) => ({
  type: types.CREATE_SUCCESS,
  data: { packages },
});
