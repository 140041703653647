import * as types from './types.js';

const INITIAL_STATE = {
  packages: {},
  notificationMessage: null,
  notificationType: null,
  isCreatingPackage: false,
};

const reducer = (state = INITIAL_STATE, action) => {
  switch (action.type) {
    case types.CREATE_CLEAR:
      return {
        ...state,
        ...INITIAL_STATE,
      };
    case types.CREATE_START:
      return {
        ...state,
        notificationMessage: null,
        notificationType: null,
        isCreatingPackage: true,
      };
    case types.CREATE_SUCCESS:
      return {
        ...state,
        packages: action.data.packages,
        notificationMessage: 'Package created',
        notificationType: 'success',
        isCreatingPackage: false,
      };
    case types.CREATE_ERROR:
      return {
        ...state,
        notificationMessage: action.data.error,
        notificationType: 'failure',
        isCreatingPackage: false,
      }
    default:
      return state;
  }
};

export default reducer;
