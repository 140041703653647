import { combineReducers } from 'redux';

import authReducer from './auth';
import deliveriesReducer from './deliveries';
import devicesReducer from './devices';
import packagesReducer from './packages';
import returnablesReducer from './returnables';
import scannerReducer, { scannerPostReducer } from './scanner';

const initialReducer = combineReducers({
  auth: authReducer,
  deliveries: deliveriesReducer,
  devices: devicesReducer,
  packages: packagesReducer,
  returnables: returnablesReducer,
  scanner: scannerReducer,
});

const rootReducer = (state, action) => {
  const intermediateState = initialReducer(state, action);
  const { scanner } = intermediateState;
  const { deliveries } = intermediateState.deliveries;
  return {
    ...intermediateState,
    scanner: scannerPostReducer(scanner, action, {deliveries}),
  };
};

export default rootReducer;
