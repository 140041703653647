import * as types from './types.js';

const INITIAL_STATE = {
  deliveries: {},
  isFetching: false,
  isImported: false,
  error: null,
};

const reducer = (state = INITIAL_STATE, action) => {
  switch (action.type) {
    case types.FETCH_START:
      return {
        ...state,
        isFetching: true,
        isImported: false,
        error: null,
      };
    case types.FETCH_SUCCESS:
      return {
        ...state,
        deliveries: action.data.deliveries,
        isFetching: false,
        isImported: true,
        error: null,
      };
    case types.FETCH_ERROR:
      return {
        ...state,
        isFetching: false,
        isImported: false,
        error: action.data.error,
      }
    default:
      return state;
  }
};

export default reducer;
