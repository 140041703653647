export const SCANNING_CODE       = 'yg_scan/returnables/SCANNING_CODE'
export const SCANNED_RETURNABLE  = 'yg_scan/returnables/SCANNED_RETURNABLE'
export const SCANNED_UNKNOWN     = 'yg_scan/returnables/SCANNED_UNKNOWN'
export const RETURNING_ITEM      = 'yg_scan/returnables/RETURNING_ITEM'
export const RETURN_ITEM_SUCCESS = 'yg_scan/returnables/RETURN_ITEM_SUCCESS'
export const RETURN_ITEM_ERROR   = 'yg_scan/returnables/RETURN_ITEM_ERROR'
export const CANCEL_RETURN       = 'yg_scan/returnables/CANCEL_RETURN'
export const SCAN_ANOTHER        = 'yg_scan/returnables/SCAN_ANOTHER'

export const scanningCode = ({code}) => ({
  type: SCANNING_CODE,
  data: { code },
})

export const scannedReturnable = ({
  code,
  orderNumber,
  returnableId,
  scannedAt = new Date(),
}) => ({
  type: SCANNED_RETURNABLE,
  data: { code, orderNumber, returnableId, scannedAt },
})

export const scannedUnknown = ({
  code,
  scannedAt = new Date(),
}) => ({
  type: SCANNED_UNKNOWN,
  data: { code, scannedAt },
})

export const returningItem = () => ({
  type: RETURNING_ITEM,
  data: {},
})

export const returnItemSuccess = ({
  creditAmount,
  previousTotal,
  updatedTotal,
}) => ({
  type: RETURN_ITEM_SUCCESS,
  data: { creditAmount, previousTotal, updatedTotal },
})

export const returnItemError = ({error}) => ({
  type: RETURN_ITEM_ERROR,
  data: { error },
})

export const cancelReturn = () => ({
  type: CANCEL_RETURN,
  data: {},
})

export const scanAnother = () => ({
  type: SCAN_ANOTHER,
  data: {},
})
