import * as types from './types.js';
import parseDeliveryCode from 'utils/parseDeliveryCode.js';

const INITIAL_STATE = {
  code: null,
  delivery: null,
  scannedAt: null,
  error: null,
};

const scanCodeReducer = (state, action, deliveries) => {
  const { code, scannedAt } = action.data;
  const { orderNumber, bayNumber, error } = parseDeliveryCode(code);

  if (error) {
    return {
      ...state,
      code,
      delivery: null,
      scannedAt,
      error,
    };
  }

  const deliveryData = deliveries[orderNumber] || {};
  const delivery = {
    orderNumber,
    bayNumber,
    ...deliveryData,
  };

  return {
    ...state,
    code,
    delivery,
    scannedAt,
    error: null,
  };
}

const reducer = (state = INITIAL_STATE, action) => {
  return state;
};

const postReducer = (state, action, {deliveries}) => {
  switch (action.type) {
    case types.SCAN_CODE:
      return scanCodeReducer(state, action, deliveries);
    default:
      return state;
  }
};

export default reducer;
export { postReducer };
